<template>
  <div>
    <div class="bg-primary-gradient min-h-[calc(100vh-80px)] flex items-center sm:px-4">
      <div class="md:w-[80%] mx-auto my-10">

        <div class="grid md:grid-cols-1 md:gap-[100px] gap-10">
          <div v-for="item in products" :key="item" class="min-h-[300px] grid md:grid-cols-2" data-aos-easing="linear"
            data-aos="zoom-in-up" data-aos-duration="1500">
            <div class="p-6 h-[80%] my-auto">
              <img v-bind:src="require(`@/assets/products/${item.image}.png`)" alt="" srcset=""
                class="h-full w-full object-contain" />
            </div>
            <div class="flex flex-col justify-center">
              <div class="leading-10 text-gray-100 text-[20px] sm:text-center pt-10 sm:pt-6" v-html="item.description"></div>
              <div class=" text-gray-100 big-title py-10" v-html="item.extra"></div>
              <div class="flex items-center sm:justify-center gap-5">
                <a href="https://play.google.com/store/apps/details?id=ai.hova.hovastore" target="_blank" class="w-[200px] sm:w-[130px] h-[80px]"><img v-bind:src="require(`@/assets/products/playstore.png`)" alt="" srcset=""
                  class="w-full h-full object-contain" /></a>
                <a href="https://apps.apple.com/fr/app/hovastore/id6479642676" target="_blank" class="w-[200px] sm:w-[120px] h-[70px]">
                  <img v-bind:src="require(`@/assets/products/appstore.png`)" alt="" srcset=""
                  class="w-full h-full object-contain" />
                </a>
              </div>
            </div>
            <!-- <div class="my-5">
              <a
                v-if="item.link"
                target="_blank"
                :href="item.link"
                class="border-[1px] border-gray-600 hover:bg-gray-600 hover:text-white uppercase px-5 py-1 text-[14px]"
                >Learn more <i class="fas fa-angle-double-right ml-3"></i></a
              >
              <a
                v-else
                href="https://calendly.com/hova-ai/hova-ai-platform-demo"
                target="_blank"
                class="border-[1px] border-gray-600 hover:bg-gray-600 hover:text-white uppercase px-5 py-1 text-[14px]"
                >Request a demo <i class="fas fa-angle-double-right ml-3"></i></a
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HwProducts",
  data() {
    return {
      products: [
        {
          image: "hovastore-mockup",
          description: `Who says keeping financial records has to be a headache? <span class="text-yellow-500">HovaStore App</span> is the game-changing solution you've been waiting for. Say goodbye to stress and hello to effortless financial management - try it out now and watch your business thrive!.`,
          title: "HovaStore",
          link: "",
          extra: "Download HovaStore App Now!",
        },
        //         {
        //           image: "tori",
        //           description: `Tori is an AI-powered virtual assistant created by HOVA AI for African SMEs
        // based in Kigali, Rwanda. Tori is designed to assist with a wide range of tasks
        // and questions and operates within this chat interface. Tori uses artificial
        // intelligence ...
        // `,
        //           title: "Tori",
        //           link: "https://tori.hova.ai/",
        //         },
      ],
    };
  },
};
</script>

<style lang="scss">
.bg-primary-gradient {
  background: linear-gradient(180deg, #571845 0%, #171A1F 100%);
}

.font-bitter {
  font-family: "Bitter";
}

.big-title {
  font-family: "Bitter";
  font-size: 56px;
  font-style: italic;
  font-weight: 700;
  line-height: 1.2;

  @media (max-width:600px) {
    font-size: 42px;
    text-align: center;
  }
}
</style>
