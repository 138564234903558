<template>
  <div
    class="min-h-[calc(100vh-80px)] sm:min-h-[75vh] bg-gray-100 flex flex-col justify-center sm:px-4"
  >
    <div class="w-full md:w-[70%] xl:w-[50%] mx-auto my-10">
      <div
        class="uppercase tracking-widest text-gray-400 mb-10 md:mb-[60px] text-center"
      >
        Meet our team
      </div>
      <div class="flex sm:flex-col justify-center md:gap-[100px] gap-10">
        <div
          class=""
          v-for="item in members"
          :key="item.title"
          data-aos-easing="linear"
          data-aos="zoom-in-up"
          data-aos-duration="1500"
        >
          <div
            class="h-[350px] w-full sm:w-full border-[1px] border-gray-400 p-2"
          >
            <img
              :src="item.image"
              alt=""
              srcset=""
              class="h-full object-top object-cover w-full"
            />
          </div>
          <div class="flex justify-between items-start my-5">
            <div class="">
              <div class="text-gray-700 font-bold text-xl">{{ item.name }}</div>
              <div class="text-gray-500">{{ item.title }}</div>
            </div>
            <div class="flex gap-5">
              <a
                :href="item.linkedin"
                target="_blank"
                class="bg-gray-200 text-blue-600 hover:bg-blue-600 hover:text-white px-2"
                ><i class="fab fa-linkedin-in"></i
              ></a>
              <a
                :href="item.twitter"
                target="_blank"
                class="bg-gray-200 text-blue-400 hover:bg-blue-400 hover:text-white px-2"
                ><i class="fab fa-twitter"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
  data() {
    return {
      members: [
        {
          name: "Xavier Ncuti",
          title: "Co-Founder and Software Developer",
          linkedin: "https://www.linkedin.com/in/xavier-ncuti-979284197/",
          twitter: "https://twitter.com/ncutixavier",
          image:
            "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzJ8fHByb2ZpbGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        },
        {
          name: "Arsene I. Muhire",
          title: "Co-Founder and Managing Director",
          linkedin: "https://www.linkedin.com/in/aimuhire/",
          twitter: "https://twitter.com/aimuhire",
          image:
            "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzJ8fHByb2ZpbGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
