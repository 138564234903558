<template>
  <div>
    <div class="bg-gray-900 min-h-[calc(100vh-180px)] flex items-center sm:px-4 sm:min-h-[75vh]">
      <div class="w-full md:w-[60%] xl:w-[50%] mx-auto my-10">
        <div class="grid md:grid-cols-2 md:gap-[100px] gap-10">
          <div class="">
            <div class="mb-8">
              <div class="text-gray-100 text-4xl">Get in touch</div>
              <div class="text-gray-400 mt-3 w-[70%] sm:w-[90%]">
                We’d love to hear from you, Our team is always here to chat.
              </div>
            </div>
            <div class="flex items-start mb-10" v-for="item in contact" :key="item.title">
              <div class="border-2 border-gray-400 w-8 h-8 mt-2 mr-5 items-center flex justify-center">
                <i class="text-gray-400 text-[14px]" :class="item.icon"></i>
              </div>
              <div class="">
                <span class="text-gray-100 font-medium text-xl">{{
                  item.title
                }}</span><br />
                <span class="text-gray-400 font-medium tracking-wider text-[13px]">{{ item.subtitle }}</span><br />
                <a :href="item.link" target="_blank" rel="noopener noreferrer" class="text-gray-100 text-[15px]">{{
                  item.linkLabel }}</a>
              </div>
            </div>

            <div class="">
              <div class="text-gray-100 font-medium text-xl">Follow us</div>
              <div class="flex mt-4">
                <a href="https://www.linkedin.com/company/hova-ai/about/" className="mr-7">
                  <div
                    class="border-2 border-gray-400 hover:text-gray-50 hover:bg-blue-600 hover:border-blue-600 w-9 h-9 flex items-center justify-center text-gray-400">
                    <i class="fab fa-linkedin-in"></i>
                  </div>
                </a>
                <a href="https://twitter.com/hova_ai" className="mr-7">
                  <div
                    class="border-2 border-gray-400 hover:text-gray-50 hover:bg-blue-400 hover:border-blue-400 w-9 h-9 flex items-center justify-center text-gray-400">
                    <i class="fab fa-twitter"></i>
                  </div>
                </a>
                <a href="https://www.instagram.com/hova.ai/" className="mr-7">
                  <div
                    class="border-2 border-gray-400 hover:text-gray-50 hover:bg-purple-500 hover:border-purple-500 w-9 h-9 flex items-center justify-center text-gray-400">
                    <i class="fab fa-instagram"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="form">
            <div class="text-gray-400">
              Do you have any enquiries? Fill the form below to reach out
              directly, Our team wil get back to you ASAP!
            </div>
            <div class="input flex flex-col h-[70px] mt-8">
              <label for="" class="text-gray-100 mb-2">Full Name</label>
              <input v-model="name" type="text" class="outline-none border bg-transparent py-1 px-3 text-gray-100" />
            </div>
            <div class="input flex flex-col h-[70px] mt-6">
              <label for="" class="text-gray-100 mb-2">Email</label>
              <input v-model="email" type="email"
                class="outline-none border border-gray-100 bg-transparent py-1 px-3 text-gray-100" />
            </div>
            <div class="input flex flex-col h-[150px] mt-6">
              <label for="" class="text-gray-100 mb-2">Message</label>
              <textarea v-model="message" rows="3"
                class="outline-none border border-gray-100 bg-transparent py-1 px-3 text-gray-100"></textarea>
            </div>
            <button class="bg-gray-400 w-full h-[38px] hover:bg-gray-950 shadow-lg uppercase hover:text-gray-50">
              Send your message
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-950 sm:px-4">
      <div class="uppercase text-[13px] w-full md:w-[60%] xl:w-[50%] mx-auto py-5 text-gray-400 flex justify-between">
        <span>HOVA.AI &copy; {{ new Date().getFullYear() }}</span>
        <span>
          <a class="mr-8" href="/privacy" rel="noopener noreferrer">Privacy</a>
          <a href="/terms" rel="noopener noreferrer">Terms</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HwContact",
  data() {
    return {
      contact: [
        {
          icon: "far fa-envelope",
          title: "Chat to us",
          subtitle: "Our team is here to help you!",
          link: "mailto:info@hova.ai",
          linkLabel: "info@hova.ai",
        },
        {
          icon: "fas fa-phone",
          title: "Phone",
          subtitle: "Mon - Sat, 9AM - 7PM",
          link: "tel:+250793090500",
          linkLabel: "+250 793 090 500",
        },
      ],
      name: '',
      email: '',
      message: ''
    };
  },
  watch: {
    name(value) {
      if (value.length > 1) {
        this.hasName = true;
      }
    },
    message(value) {
      if (value.length > 3) {
        this.hasMessage = true;
      }
    },
    email(value) {
      if (/^\S+@\S+\.\S+$/.test(value)) {
        this.hasEmail = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
