<template>
  <div class="h-screen flex items-center bg-main">
    <div class="flex flex-col w-full md:grid md:grid-cols-2 sm:px-4 h-full mx-auto md:max-w-[80%] md:px-0 px-[5%]">
      <div class="h-full flex flex-col justify-center">
        <swiper :onSwiper="setSwiperInstance" ref="mySwiper" v-bind="swiperOptions" class="mySwiper w-full">
          <swiper-slide v-for="(slide, index) in slides" :key="index">
            <div class="big-title text-white xs:mt-5">
              {{ slide.title }}
            </div>
            <div class="my-10 sm:my-4 leading-8 w-full md:w-[90%] text-gray-400 sm:text-center">
              {{ slide.description }}
            </div>
          </swiper-slide>
        </swiper>
        <div class="flex gap-2 md:justify-start justify-center" v-if="slides.length > 2">
          <button @click="handlePrev" class="w-8 h-8 flex justify-center items-center bg-white">
            <i class="fas fa-angle-left text-[20px]"></i>
          </button>
          <button @click="handleNext" class="w-8 h-8 flex justify-center items-center bg-white">
            <i class="fas fa-angle-right text-[20px]"></i>
          </button>
        </div>
      </div>
      <div class="flex flex-col justify-center sm:hidden">
        <img src="@/assets/insight.png" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import SwiperCore, { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

export default {
  name: "HwOnboard",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      slides: [
        {
          title: "Unlock Your Potential with Data-Driven Insights",
          description: "Transform your business landscape by leveraging our innovative platform tailored for African SMEs. Say goodbye to uncertainty and hello to actionable insights that drive success."
        },
        {
          title: "Consultancy Services",
          description: "Unlock strategic advantages with our expert consultancy services. We guide you through the complexities of analytics to maximize your business potential."
        },
        {
          title: "Say Goodbye to Guesswork",
          description: "Embrace a future where every decision is backed by robust data analytics. Our tools empower you to make informed choices that accelerate growth and improve efficiency."
        },
      ],
      swiperOptions: {
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        speed: 6000,
        pagination: false,
        navigation: false,
      },
      swiperInstance: null,
    };
  },
  methods: {
    setSwiperInstance(swiper) {
      this.swiperInstance = swiper;
    },
    handlePrev() {
      if (this.swiperInstance) this.swiperInstance.slidePrev();
    },
    handleNext() {
      if (this.swiperInstance) this.swiperInstance.slideNext();
    },
  },
};
</script>

<style scoped lang="scss">
.bg-main {
  background: linear-gradient(180deg, #571845 10%, #171A1F 100%);
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.slide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 2rem;
}

.big-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.swiper-pagination-bullet-active {
  background: #ffffff;
}
</style>
