<template>
  <div>
    <div class="privacy sm:px-4 md:w-[80%] mx-auto">
      <div>
        <h1>Privacy Policy</h1>
        <p>Last updated: August 31, 2023</p>

        <h2>Introduction</h2>
        <p>
          Welcome to HOVA AI. We are dedicated to safeguarding your privacy. This Privacy Policy outlines the types of
          personal data we gather when you interact with our services, and how we use, store, and safeguard that
          information.
        </p>

        <h2>Information We Collect</h2>
        <p>
          Your personal data is collected when you utilize our website or avail of our services. The categories of
          information we gather include:
        </p>
        <ul>
          <li>Contact details such as your name, email address, TIN, and phone number</li>
          <li>Device and activity data, including your IP address, browser type, and operating system</li>
          <li>Additional information you directly furnish to us</li>
        </ul>

        <h2>Utilization of Your Information</h2>
        <p>Your personal data serves various purposes, such as:</p>
        <ul>
          <li>Furnishing you with our services</li>
          <li>Processing your transactions data securely</li>
          <li>Engaging with you about our services and updates</li>
          <li>Sending targeted marketing messages relevant to your interests</li>
        </ul>

        <h2>Data Sharing Practices</h2>
        <p>
          Your personal data may be shared with trusted third-party service providers who carry out functions on our
          behalf. This includes payment processors and email delivery services. We may also share data with our affiliates
          and strategic partners.
        </p>

        <h2>Data Security</h2>
        <p>
          Protecting your personal data is paramount to us. We employ robust technical and organizational controls to
          prevent unauthorized access, disclosure, or alterations.
        </p>

        <h2>Child Safety</h2>
        <p>
          Our services, including the HovaStore app and analytics features, are not designed for children under 13 years
          of age. We do not intentionally collect data from this age group. Should we discover that such data has been
          collected, we will promptly take measures to delete it.
        </p>

        <h2>Analytics</h2>
        <p>
          We employ analytics tools like Google Analytics and Firebase Analytics for internal assessments of app
          performance and user behavior. This data is strictly for in-house use and not sold to third-party advertisers.
          For more details, view Google’s Privacy Policy: <a href="https://policies.google.com/privacy"
            target="_blank">here</a>.
        </p>

        <h2>No In-App Advertising</h2>
        <p>
          Our application is free of third-party advertisements and does not collect data for advertising purposes.
        </p>

        <h2>Data Retention and Deletion</h2>
        <p>
          Your data is stored as long as necessary for delivering our services to you and to comply with legal mandates.
          Should you request data deletion, contact us at info@hova.ai, and we'll comply as promptly as possible, keeping
          in mind any legal obligations.
        </p>

        <h2>Your Data Rights</h2>
        <p>
          You are entitled to access, correct, or delete your personal data held by us. You can also request limitations
          on or object to our data processing activities.
        </p>

        <h2>Contact Information</h2>
        <p>For any queries related to privacy, kindly reach out to us at info@hova.ai.</p>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss">
.privacy {
  @apply mb-8;

  h1,
  h2 {
    @apply text-[22px] font-semibold mt-6 mb-4 text-gray-700;
  }

  h1 {
    @apply text-4xl;
  }

  p,
  li {
    @apply text-gray-600 text-[18px] leading-9 text-justify;
  }

  ul {
    @apply list-disc ml-10;
  }
}
</style>
