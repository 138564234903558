<template>
  <div>
    <div class="privacy sm:px-4 md:w-[80%] mx-auto">
      <h1>Terms of Service</h1>
      <p>Last Updated: August 31, 2023</p>

      <h2>Introduction</h2>
      <p>
        Thank you for choosing HOVA AI. By accessing or using our products and services, you agree to comply with and be
        bound by the following Terms of Service. We strongly urge you to read these terms carefully and in their entirety.
        If you do not agree with these Terms of Service, you should discontinue your use of our products and services
        immediately.
      </p>

      <h2>Use of Our Products and Services</h2>
      <p>
        You are permitted to use our products and services exclusively for lawful purposes and in compliance with these
        Terms of Service, as well as any applicable federal, state, local, or international laws and regulations.
        Prohibited usage includes, but is not limited to:
      </p>
      <ul>
        <li>
          Violation of any applicable laws or regulations
        </li>
        <li>
          Conduct that restricts or inhibits other users' ability to use and enjoy our products and services
        </li>
        <li>
          Transmission or dissemination of unsolicited advertising or promotional materials, including "junk mail," "chain
          letters," "spam," and other similar solicitations
        </li>
      </ul>

      <h2>Intellectual Property</h2>
      <p>
        All content and materials made available through our products and services, including but not limited to text,
        graphics, logos, trademarks, audio and video files, software, and images, are the exclusive property of HOVA AI,
        its affiliates, or its licensors. These are protected under international copyright, trademark, and other
        intellectual property laws. Unauthorized use, reproduction, modification, distribution, transmission,
        republishing, display, or creation of derivative works of these materials is strictly prohibited without our
        express written consent.
      </p>

      <h2>Disclaimers and Limitations of Liability</h2>
      <p>
        Our products and services are offered on an "as is" and "as available" basis, without any warranties of any kind,
        either express or implied, including but not limited to the warranties of merchantability, fitness for a
        particular purpose, or non-infringement. HOVA AI disclaims all liability for damages, direct or consequential,
        arising from your use of our products and services, to the maximum extent permitted by law.
      </p>

      <h2>Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless HOVA AI, its affiliates, directors, officers, employees, agents,
        licensors, and suppliers from and against any and all claims, liabilities, damages, losses, costs, and expenses,
        including reasonable attorneys' fees, resulting from your use of our products and services, your breach of these
        Terms of Service, or your infringement of any law or the rights of a third party.
      </p>

      <h2>Changes to These Terms of Service</h2>
      <p>
        HOVA AI reserves the right to revise or modify these Terms of Service at any time and at our sole discretion. Any
        changes or modifications will be effective immediately upon posting the revised Terms of Service on our website.
        Your continued use of our products and services following the posting of changes constitutes your acceptance of
        such changes.
      </p>

      <h2>Termination</h2>
      <p>
        These Terms of Service are effective until terminated by either you or HOVA AI. We reserve the right to terminate
        or suspend these Terms of Service and your access to our products and services at any time, for any reason, and
        without prior notice. Upon termination, you must cease all use of our products and services immediately.
      </p>

      <h2>Governing Law</h2>
      <p>
        These Terms of Service are governed by and construed in accordance with the laws of the jurisdiction in which HOVA
        AI is registered, without regard to its conflict of law principles.
      </p>

      <h2>Contact Information</h2>
      <p>
        For any inquiries related to these Terms of Service, please contact us at: info@hova.ai
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
