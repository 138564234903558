<template>
 <div class="">
  <Onboard id="home" />
  <Services id="services" />
  <About />
  <Products id="products" />
  <!-- <Team /> -->
  <Testimonies id="testimonies" />
  <Contact id="contact" />
 </div>
</template>

<script>
import Onboard from '@/components/Onboard.vue';
import About from '@/components/About.vue'
import Services from '@/components/Services.vue';
import Products from '@/components/Products.vue';
import Team from '@/components/Team.vue';
import Testimonies from '@/components/Testimonies.vue';
import Contact from '@/components/Contact.vue';

export default {
  name: 'HwHome',
  components: {
    Onboard,
    About,
    Services,
    Products,
    Team,
    Testimonies,
    Contact
  }
}
</script>

<style scoped lang="scss"></style>

