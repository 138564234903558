<template>
   <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
    integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
    crossorigin="anonymous"
  />
  <router-view />
</template>

<script>
import AOS from "aos";

export default {
  name: "MainLayout",
  mounted() {
    AOS.init();
  },
};
</script>

<style lang="scss"></style>
